@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
:root{
    --black: #000;
    --primary-color:#34287E;
}
// Override Boostrap variables
@import "bootstrap-variables";

// Import Bootstrap source files
@import "bootstrap/scss/bootstrap.scss";

// Import core styles
@import "core/core";

// Custom styles
@import "custom";
