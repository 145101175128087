// Here you can add other styles
body{
  font-family: 'Inter', sans-serif;
  font-size:1rem;
  font-weight: 400;
  color:var(--black);
  overflow: auto !important;
  padding-right: 0px !important;
}
.page-header {
  padding: $spacer 0;
  background: $breadcrumb-bg;
  border-bottom: 1px solid $border-color;

  h1 {
    margin: 0;
  }

  .chart-wrapper {
    max-width: 140px;
    margin: 0 auto;
    overflow: visible;
  }

  .charts .title {
    margin-top: 2px;
  }
}

.sidebar {
  .sidebar-header {

    .img-avatar {
      width: 80px;
      margin: 20px auto 10px;
      border-radius: 50em;
    }

    > .btn-group {
      margin-top: 10px;
    }

    .btn-link {
      color: $text-muted;

      &:hover {
        color: $sidebar-color;
        text-decoration: none;
      }
    }
  }
}
.card {
  border: 0;
  box-shadow: 0 1px 1px 0 rgba(60,75,100,.14), 0 2px 1px -1px rgba(60,75,100,.12), 0 1px 3px 0 rgba(60,75,100,.2);
  border-radius: 3px;
}
.sidebar .sidebar-nav{border-right:1px solid #eee;}
.card-list h4 {
  font-size: 2.25rem;
}
.card-list p {
  font-size: 1rem;
  color: var(--secondary);
}
.card-list i {
  font-size: 3rem;
  position: absolute;
  right: 20px;
  top: 20px;
  opacity: 0.3;
}
.cursor-pointer{cursor: pointer;}
.btn-black{background:var(--black);color: var(--white);border: 1px solid var(--black);}
.btn-black:hover{background:var(--white);color: var(--black);}
.login-admin .form-control {
  width: 100%;
  border: none;
  padding: 12px 0px;
  outline: none;
  border-bottom: 1px solid #ddd;
  height: auto;
  border-radius: 0;
}
.form-control:focus {
  border-color: inherit !important;
  color: inherit !important;
}
.form-control {
  height: auto;
  padding: 0.75rem 1rem;
  border-radius: 5px;
  font-size: 0.95rem;
}
.menu-dropdown i {
  font-size: 12px;
  margin-left: 5px;
  font-weight: bold;
}
.btn {
  border-radius: 5px;
  padding: 0.5rem 0.75rem;
  font-size: 0.95rem;
}
.table th, .table td {
  font-size: 0.95rem;
}
.sidebar .nav-link, .sidebar .nav-link i{transition:0.3s ease}
.sidebar .nav-link:hover{background-color: #eee;color:var(--black)}
.sidebar .nav-link:hover i{color:var(--black)}
.sidebar .nav-link.active:hover{background-color:var(--black);color:var(--white)}
.sidebar .nav-link.active:hover i{color:var(--white)}
.admin-logo img {
  max-width: 200px;
}
input:focus {
  box-shadow: none !important;
}
.sidebar .nav-link {
  padding: 1rem 1rem;
  font-size: 1rem;
}
button:focus{outline:none ;}