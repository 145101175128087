.table-outline {
  border: 1px solid $gray-200;

  td {
    vertical-align: middle;
  }
}

.table-align-middle {

  td {
    vertical-align: middle;
  }
}

.table-clear {
  td {
    border: 0;
  }
}
